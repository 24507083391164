@import "src/styles/exports";

.basicButton {
  border: 0 solid transparent;

  font-weight: bold !important;
  //line-height: 17px;
  font-family: $font-family-main;
  font-size: $font-size-main;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  &:hover {
    cursor: pointer;
  }

  .orange {
    border: 1px solid $primary-color;
    background: $common-white !important;
    color: $primary-color !important;
    padding: 0 spacing(8) !important;

    &:hover {
      background: #FFF9F7 !important;
    }

    &.active {
      background: #FFCCBA !important;
      color: $common-white !important;
    }
  }

  &.primary {
    color: $common-white;
    background: $button-primary-gradient;

    &:hover {
      background: #FFCCBA;
    }

    &:active {
      background: $primary-color;
    }
  }

  &.secondary {
    color: $primary-color;
    background: $common-white;
  }

  &.transparent {
    background-color: transparent!important;
    color: $common-white;
  }

  &.inline {
    display: inline-block;
    width: auto;
  }

  &.stretch {
    width: 100%;
    padding: unset !important;
    align-items: center;
  }

  &.shadow {
    box-shadow: $btn-box-shadow;
  }

  &.rounded {
    border-radius: 66px !important;
  }

  &.contained {
    border-radius: 8px;
  }

  &.outlined {
    background: unset !important;
    border: 1px solid $common-white;

    &:hover {
      background: $gray-8 !important;
    }

    &:active {
      background: $gray-11 !important;
      color: $common-white !important;
    }
  }

  &.primary,
  .grey {
    &:disabled {
      border-color: $gray-2 !important;
      background: $gray-2 !important;
      color: $gray-1 !important;
      cursor: not-allowed;
    }
  }
  &.grey {
    border-color: $gray-2 !important;
    color: $gray-2 !important;
    background: $common-white !important;
  }

  &.white {
    background-color: $common-white;
    color: $primary-color;
    border-color: $primary-color;
  }

  &.orange {
    border: 1px solid $primary-color;
    background: $common-white !important;
    color: $primary-color !important;
    padding: 0 spacing(8) !important;

    &:hover {
      background: #FFF9F7 !important;
    }

    &:active {
      background: #FFCCBA !important;
      color: $common-white !important;
    }
  }

  &.secondary {
    background: $button-purple2-gradient !important;
    color: $common-white !important;
    &:hover {
      background: #C38BFF !important;
    }

    &:active {
      background: #7F59E4 !important;
    }

  }

  &.xs {
    height: 30px;
    padding: 0 spacing(1);
  }

  &.sm {
    height: 40px;
    padding: 0 spacing(9);
  }

  &.md {
    font-size: 14px;
    min-height: 50px;
    font-weight: 600;
    font-style: normal;
    padding: 0 spacing(9);
  }

  &.lg {
    height: 60px;
    font-weight: 600;
    font-style: normal;
    padding: 0 spacing(12);
  }

  &.xl {
    height: 70px;
    //line-height: 19.5px;
    font-weight: 600;
    font-style: normal;
    padding: 0 spacing(7);
  }

  &.noPadding {
    padding: 0;
  }

  &.tabs {
    border-radius: 15px;
    max-width: 105px;
  }

  &:focus {
    outline: none !important;
  }

  &:active {
    outline: none !important;
  }


  &.iconBtn {
    width: 60px;
    height: 60px;
    padding: unset;

    & svg {
      margin: unset;

    }
  }

  &.disabled {
    background: #C4C4C4;
    cursor: unset;

    &:hover {
      background: $gray-1;
    }

    & > div {
      color: $common-white;
      & > svg {
        color: $common-white;
      }
    }


  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;


  &.start {
    flex-flow: row;
  }

  &.end {
    flex-flow: row-reverse;
  }
}

.icon {

}

.default {

  &.primary {
    color: $common-white;
  }

  &.grey {
    color: $gray-1;
  }
}
