.root {
	//
  //& .MuiBackdrop-root {
  //  background: rgba(177, 177, 177, 0.4) !important;
  //  backdrop-filter: blur(30px) !important;
  //}
	//
  //background-color: red !important;

}

.paperRoot {
  border-radius: 8px !important;
}

.backdrop {
  background: rgba(177, 177, 177, 0.4) !important;
  backdrop-filter: blur(30px) !important;
}
