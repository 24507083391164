@import "src/styles/exports";

.root {
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: $common-white;

}

.item {
  max-height: 30px;
  height: 30px;
  max-width: 30px;
  width: 30px;
  color: $gray-1;

  &.selected {
    color: $primary-color;
  }
}

.avatarSelected {
  border: 1px solid $primary-color;
}