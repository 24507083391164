@import "src/styles/exports";

.root {
  color: $purple-1;
  font-weight: 600;

  font-size: 12px;

  @include respond-to(lg) {
    font-size: 14px;
  }
}