@import "src/styles/exports";

.root {
  position: relative;
  width: 100%;

  & > span {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 5px;

    @include respond-to(sm) {
      margin-top: 10px;
    }
  }
}

.error {
  border: 2px solid $error-color !important;
}

.infoDialogBody {
  font-weight: bold !important;
  font-size: 18px !important;
}