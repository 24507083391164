@import "src/styles/exports";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: spacing(6) spacing(2) spacing(3) spacing(2);

  @media (min-width: 1366px) {
    padding: spacing(6) spacing(4);
  }
}

.mainTitle {
  @media (min-width: 1366px) {
    font-size: 30px;
  }
}

.mainDesc {
  @media (min-width: 1366px) {
    font-size: 20px;
  }
}

.sectionTitle {
  @include my(2);
  @media (min-width: 1366px) {
    font-size: 20px;
  }
}

.iconsField {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: rgba(250, 236, 231, 0.5);
  padding: spacing(2) spacing(4);
  border-radius: $border-radius-1;
}

.iconsContainer {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
}

.icon {
  color: $purple-1;
  margin-bottom: spacing(2);
}

.iconItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 50px;

  @media (min-width: 1366px) {
    max-width: 160px;
  }
}

.iconsTitle {
  font-size: 10px;
  margin-bottom: spacing(1.5);

  @media (min-width: 1366px) {
    font-size: 18px;
  }
}

.iconDesc {
  font-size: 8px;
  font-weight: 500;

  @media (min-width: 1366px) {
    font-size: 14px;
  }
}

.btn {
  @media (min-width: 1366px) {
    height: 50px;
  }
}