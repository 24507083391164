@import "src/styles/exports";

@font-face {
  font-family: $font-family-main;
  src: url('../assets/fonts/Montserrat-Regular.ttf');
  src: url('../assets/fonts/Caladea-Bold.ttf');
}

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  color: $black-2;
}

body {
  margin: 0;
  background-color: $app-bg;
}

#root {
//   display: flex;
  flex-direction: column;
  min-height: 100%;
}

a {
  text-decoration: none;
  outline: none;
  color: $black-2;
  -webkit-tap-highlight-color: transparent;
}

button,
input,
select,
textarea {
  outline: none;
}

// For prevent whitespace under these elements
img,
svg {
  vertical-align: top;
}

// Google reCAPTCHA
.grecaptcha-badge {
	visibility: hidden !important;
}
