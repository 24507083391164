@import "styles/exports";

.root{
	position: absolute;
	z-index: 200;
	top: 70px;
	right:10%;
	display: flex;
	flex-direction: column;
}

.buttonsContainer {
	position: absolute;
	right: -15px;
	top: -6px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 130px;

	@media(max-width: 769px) {
		right: -33px;
	}

	@media(max-width: 425px) {
		right: -11px;
	}
	@media(max-width: 320px) {
		right: -22px;
	}
}

.item {
	display: flex;
	align-items: center;
}

.btn {
	margin-left: 10px;

	&.report {
		top: 20px;
	}

	&.reply {
		top: 70px;
	}

	&.message {
		top: 120px;
	}
}
