@import "src/styles/exports";

.root {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin: 0 spacing(1);
  background-color: $common-white;
  transition: background-color 1s;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  &.active {
    background-color: $primary-color
  }
}
