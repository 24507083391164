@import 'styles/exports';

.root{
	z-index: 200;
	flex-grow: 1;
}

.filter {
	position: absolute;
	top: 15px;
	right:50px;
}

.nav {
	display:flex;
	width: 100%;
	justify-content: space-around;
	position: fixed;
	bottom:60px;
	color:white;
}

.navigationBar {
	box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
	position: absolute;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.1);
}

.navigationBar a {
	background-color: white;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.filtersBtn {
	width: 25px;
	height: 25px;
	position: absolute;
	right: 12px;
	top: 12px;
	color: black;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
