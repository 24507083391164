@import "src/styles/exports";

.root{
	z-index: 100;
	@include flex-center;
	padding: 25px;
	min-height: 200px;
	flex-direction: column;

	@include respond-to(sm) {
		padding: 40px;
	}
}

.modal {
	background: $common-white;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	flex-grow: 1;
	justify-content: space-evenly;

	//@include respond-to(xs) {
	//	width: 60%;
	//}
}

.dialog {
	z-index: 1000000 !important;
}

.modalHeader {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	width: 100%;
	min-height: 70px;
	justify-content: space-around;
}

.modalTitle {
	font-family: $font-family-main;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: $black-2;
	text-align: center;
	margin-bottom: 20px;
}

.modalSubtitle {
	font-family: $font-family-main;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	margin-bottom: 20px;

	&.bold {
		font-weight: bold !important;
		font-size: 18px !important;
	}
}

.buttons {
	display: flex;
	//width: 100%;
	//justify-content: space-around;
	& > button {
		&:first-child {
			margin-right: 10px;
		}
		flex-grow: 1;
	}
}

.btn {
	padding: 0 40px !important;
	max-height: 50px;
	height: 40px !important;



	@include respond-to(sm) {
		padding: 0 50px !important;
	}
}

.outlinedBtn {
	border-color: $gray-8 !important;
	color: $gray-8 !important;
}

.cancelBtn {
	color: $gray-2 !important;
	background: $common-white !important;
	border: 1px solid $gray-2 !important;


}
