@import "src/styles/exports";

.backdrop {
  backdrop-filter: blur(5px) !important;
}

.root {

  @include respond-to(sm) {
    min-height: 500px;
    max-height: 600px;
    min-width: 600px;
    width: 100%;
    height: 100%;
  }
}

.rotate {
  position: absolute;
  top: 30px;
  right: 30px;
  color: $primary-color;
  cursor: pointer;

  &:hover {
    transition: .2s;
    color: $common-white;
  }
}

.button {
  position: absolute;
  color: $common-white;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;

  @include respond-to(lg) {
    font-size: 28px;
  }

  &.right {
    bottom: 30px;
    right: 30px;
    @include respond-to(lg) {
      color: $primary-color;
    }
  }
  &.left {
    bottom: 30px;
    left: 30px;
  }
}
