@import "src/styles/exports";

.root {
  color: $error-color;
  font-family: $font-family-main;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;

  @include respond-to(lg) {
    font-size: 14px;
  }

}