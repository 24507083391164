@import "src/styles/exports";

.root {
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  grid-gap: 10px;


  &.primary {
    grid-template-rows: 160px;

    @media (min-width: 426px) {
      grid-template-rows: 260px;
    }
  }


  &.secondary {
    grid-template-rows: 120px;

    @media (min-width: 426px) {
      grid-template-rows: 220px;
    }
  }
}

.tooltipIcon {
  right: unset;
  transform: unset;
  top: 13px;
  left: 15px;
  color: $primary-color;
  width: 20px;
  height: 20px;
}