@import "src/styles/exports";
// Text

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// Images

.object-fit-cover {
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

//Position

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overflow-auto {
  overflow: auto;
}

.grow {
  flex-grow: 1;
}

// Color

.primary-color {
  color: $primary-color;
}
.purple-color {
  color: $purple-1;
}
.white-color {
  color: $common-white !important;
}

.interactive-btn {
  cursor: pointer !important;
  &:active {
    -webkit-box-shadow: 0px 0px 21px -7px rgba(0, 0, 0, 0.47) inset !important;
    -moz-box-shadow: 0px 0px 21px -7px rgba(0, 0, 0, 0.47) inset !important;
    box-shadow: 0px 0px 21px -7px rgba(0, 0, 0, 0.47) inset !important;
  }
}

.user-select-none {
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

.link {
    color: blue;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 200px;
    height: 17px;
    vertical-align: top;
}


@for $i from 0 through 10 {
  .grow-#{$i} {
    flex-grow: $i !important;
  }
}
