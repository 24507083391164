@import "src/styles/exports";

.root {

}

.btn {
  margin-top: spacing(2);
  padding: 0 40px !important;

  & > div {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #3E3E3E;
  }
}
