@import "src/styles/exports";

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.root {
  z-index: 2;
  //position: absolute;
  height: 70px;
  width: 70px;
  object-fit: cover;
  object-position: top;
  border: unset;

  &.round {
    border-radius: 50px;
  }

  &.square {
    border-radius: 8px;
  }


}

.isSelected {
  position: absolute;
  height: 120%;
  width: 120%;
  border-radius: 50%;
  z-index: 1;
  background-color: $primary-color;

}

.wrapper {
  z-index: 2;
}
