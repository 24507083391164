@import "src/styles/exports";

.scrollTrack {
  bottom: 1px;
  top: 1px;
  right: 1px;
  border-radius: 2px;
  z-index: 10;
  transition: opacity 500ms ease
}
.scrollThumb {
  background: $gray-1;
  height: 40px;
  width: 10px;
  border-radius: inherit;
  cursor: pointer
}
