@import "src/styles/exports";

.root {
  font-family: $font-family-main;
  font-weight: 500;
  font-style: normal;
  cursor: pointer;

  &.secondary {
    color: $primary-color;
  }
  &.primary {
    color: $common-white;
  }
  &.underline {
    text-decoration: underline;
  }
}