@import 'src/styles/exports';

.root {
	display: flex;
	flex-direction: column;
	//width: 100%;
}

.carouselContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	width: 100%;
}

.arrow {
	cursor: pointer;
	min-width: 64px;
	min-height: 64px;

	&.prev {
		margin-right: spacing(2);
		transform: rotate(180deg);
	}

	&.next {
		margin-left: spacing(2);
	}
}

.dot {
	@media (max-width: 450px) {
		border: 1px solid $primary-color;
	}
}

.dotsContainer {
	margin-top: 25px !important;
	display: flex;
	justify-content: center;
	align-items: center;
}
.dotsContainer {
	display: none;
}
