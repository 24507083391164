@import "src/styles/exports";

.root {
  z-index: 9999999;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //height: 100vh;
  //width: 100vw;
  background-color: rgba(232, 223, 223, 0.8);
}