@import "src/styles/exports";

.root {
  height: $input-sm-height;
  background-color: $gray-5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-1;
  font-size: 14px;
  font-weight: 500;
  font-family: $font-family-main;
  cursor: pointer;
  text-align: center;
  word-break: break-word;
  margin: 4px;
  flex: 1 1 auto;
  padding: spacing(1);

  &.secondary {
    background-color: $common-white;
    border: 1px solid $gray-1;
    flex: 1 1 100%;

    @include respond-to(lg) {
      flex: 1 1 260px;
    }
  }

  @include respond-to(lg) {
    font-weight: 600;
    background-color: $common-white;
    border: 1px solid $gray-1;
    height: $input-md-height;
    flex: 1 1 260px;
    font-size: 20px;
    line-height: 27px;
  }

  &.active {
    background: $primary-gradient-color;
    font-weight: 700;
    color: $common-white;
    border: 0;
  }
}
