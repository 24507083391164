@import "src/styles/exports";

.carousel {
  min-height: 170px;
  padding: 10px 25px 15px 25px;
  width: 100% !important;
  background: $purple-gradient;
  box-shadow: $box-shadow;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  justify-content: space-evenly;
}

.dot {
  background-color: $blue;
  border: none;
}

.activeDot {
  background-color: $common-white !important;
}

.carouselItem {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 100%;
}
.slideTitle {
  font-family: Caladea, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: $common-white;
}

.slideSubtitle {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: $common-white;
  margin-top: 15px;
}

