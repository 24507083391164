@import "src/styles/exports";

.title {
  font-family: $font-family-secondary;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: $common-white;
}

.cardBtn {
  color: $black-3 !important;
  background: $common-white !important;
  box-shadow: $header-text-shadow;
  height: 40px !important;
  padding: 0 spacing(3) !important;
  margin-top: spacing(3);
}

.card {
  background: $purple-gradient;
  box-shadow: $box-shadow;
  border-radius: 10px;
  //height: 150px;
  color: $common-white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  padding: 20px;
}
