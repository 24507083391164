@import "src/styles/exports";

.root {
  cursor: pointer;
  position: relative;
  padding: spacing(1) spacing(2);
  border-radius: $border-radius-1;
  border: 2px solid $gray-2;
  display: flex;
	flex-direction: column;
  height: 85px;
	justify-content: space-evenly;

  &.selected {
    border-color: $primary-color;
  }
}

.icon {
  position: absolute;
  right: 10px;
  color: $primary-color;
}

.title {
  display: flex;
  align-items: center;
  font-family: $font-family-main;
  font-size: 14px;
  font-weight: bold;
}

.answer {
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 90%;
	overflow: hidden;
	font-family: Montserrat, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	color: $black-3;
	max-width: 250px;
}
