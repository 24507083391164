@import "styles/exports";

.root{
	position: absolute;
	z-index: 200;
	bottom: 30%;
	right: 25%;
}

.btn {
	position: fixed;
	bottom: 60px;
	padding: unset !important;
	width: 70px;
	z-index: 3;
	filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));

	&.likeBtn {
		right: spacing(2);
	}
	&.passBtn {
		left: spacing(2);
	}
}

.filtersBtn {
	width: 20px;
	height: 20px;
	align-self: flex-end;
	margin-right: 12px;
}

.icon {
	width: 40px;
	height: 40px;
}

