@import "src/styles/exports";

$icon-wrapper-width: 50px;

.root {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  position: absolute;
  left: 0;
  transform: translateX(15px);
  z-index: 1;
  width: 12px;
  height: 19px;

  @include respond-to(lg) {
    width: 18px;
    height: 29px;
  }
}

.input {
  border-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding-left: 0;
  color: $primary-color;
  font-weight: 700;
  font-family: $font-family-secondary;
  font-size: 20px;
  line-height: 27px;

  @include respond-to(sm) {
    font-size: 22px;
    line-height: 30px;
  }
}

.rootInput {
  width: calc(100% - 50px);
}

.iconWrapper {
  height: 50px;
  width: $icon-wrapper-width;
  display: flex;
  align-items: center;

  border: 1px $primary-color solid;
  border-right: 0;
  border-radius: $border-radius-1 0 0 $border-radius-1;

  @include respond-to(sm) {
    height: 60px;
  }

  &.disabled {
    background: $gray-5;
  }
}

.options {
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  height: 200px;
  width: 100%;
  & > div {
    border-radius: 8px;
  }
}

.optionsContent {
    overflow: hidden;
    & > div {
      padding: 15px;
      background: $beige-1;

      cursor: pointer;
      position: relative;
      &::after {
        position: absolute;
        width: 95%;
        height: 100%;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        content: '';
        z-index: 2;

        border-bottom: 1px solid $gray-7;
      }
      &:hover {
        background: #f9eeee;
      }
      &:active {
        background: #ffe6e8;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        &::after {
          border-bottom: unset;
        }
      }
    }

}
