$app-bg: #F0F0F0;
$primary-color: #FC8C66;
$primary-gradient-color: linear-gradient(90deg, #FC8C66 0%, #FFCCBA 162.36%);
$button-primary-gradient: linear-gradient(90.8deg, #FC8C66 -3.78%, #FFCCBA 114.82%);
$primary-gradient-color-2: linear-gradient(44.84deg, #FC8C66 29.56%, rgba(237, 78, 23, 0.19) 126.14%);
$primary-homepage-gradient-color: linear-gradient(101.33deg, #E2A59E 4.54%, #ECC4AA 77.66%, #F6E5B7 101.39%);
$purple-gradient: linear-gradient(180deg, #7D50FF 0%, #C377FF 100%);
$button-purple-gradient: linear-gradient(90.8deg, #8153FF -3.78%, #CE94FF 114.82%);
$button-purple2-gradient: linear-gradient(90deg, #7D50FF 0%, #D196FF 100%);
$blue: #5A3AB4;
$help-page-gradient: linear-gradient(90deg, #FC8C66 0%, #FFCCBA 162.36%);
$error-color: #FF0000;;
$black-1: #000000;
$black-2: #272727;
$black-3: #3E3E3E;
$gray-1: #C4C4C4;
$gray-2: #A1A1A1;
$gray-3: #E9E9E9;
$gray-4: #E5E5E5;
$gray-5: #F0F0F0;
$gray-6: #E3E3E3;
$gray-7: #D1D1D1;
$gray-8: #F4F4F4;
$gray-9: #A2A2A2;
$gray-10: #ABABAB;
$gray-11: #DEDEDE;
$gray-12: #F9F9F9;
$purple-1: #C494EB;
$purple-2: #EED8FF;
$purple-3 :#8254FF;
$purple-4: #7D50FF;
$common-white: #ffffff;
$secondary-color: #ffb66c;
$dotColor: #C77458;
$beige-1: #FFE6DB;
$photo-card-color: rgba(241, 176, 154, 0.21);
$success-color: #5FDB5C;
