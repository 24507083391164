@import "src/styles/exports";

.root {
  padding: spacing(3);
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  background: $common-white;

  @include respond-to(sm) {
    align-items: stretch;
    padding: spacing(6) spacing(10);
  }

  @media (min-width: 1510px) {
    padding: spacing(11) spacing(15);
  }
}

.container {
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 100px;
  flex-grow: 1;

  @include respond-to(sm) {
    max-width: 800px;
    //padding-top: 200px;
    padding-bottom: 0;
  }

  @include respond-to(lg) {
    max-width: 800px;
    //padding-top: 200px;
    padding-bottom: 0;
  }
}

.buttonsSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //margin-top: spacing(4);
  flex-grow: 1;
}

.btn {
  //align-self: flex-end;
  font-size: 28px;
  line-height: 34px;
  min-width: unset !important;
  z-index: 2;
  //flex-grow: 1;
  position: fixed;
  bottom: 5vh;
  right: 32px;

  @include respond-to(sm) {
    min-width: 100px;
    max-width: 300px;

    bottom: 60px;
    right: 60px;
  }

}


.educationTitleWrapper {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
}

.title {

  align-self: flex-start;
  font-size: 27px !important;
  line-height: 35px !important;
  font-weight: 400;

  &.education {
    white-space: pre;
  }

  @include respond-to(sm) {
    font-size: 55px !important;
    line-height: 63px !important;
  }
}



.link {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  white-space: pre;
  margin-right: spacing(4);

  @include respond-to(sm) {
    font-size: 18px;
    line-height: 25px;
    margin-right: spacing(8);
  }
}

.description {
  align-self: flex-start;
  font-size: 14px;
  line-height: 20px;

  @include respond-to(sm) {
    font-size: 20px;
    line-height: 30px;
  }
}

.firstNameContainer {
  display: grid;
  grid-auto-flow: row;

  width: 100%;
  grid-gap: spacing(2);

  @include respond-to(sm) {
    grid-auto-flow: column;
  }

  & span {
    position: absolute;
    top: 100%;
    padding-top: 10px;
  }
}

.input {
  color: $primary-color;
  size: 20px;
  line-height: 26px;
}

.icon {
  width: 37px;
  height: 37px;
  color: $purple-1;
  align-self: flex-start;
  @include my(2);
}

.jobsInput {
  & > div {
    font-size: 12px;
    font-weight: 500;
    padding: 0 11px;
    @include respond-to(sm) {
      flex: 1 1 260px;
    }
  }
}

.whiteSmooth {
  position: fixed;
  width: 100vw;
  height: 100px;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, $common-white 25%, transparent 100%);
  @include respond-to(sm) {
    bottom: 0;
    left: 0;
  }
}

.hint {
  align-self: flex-start;
  margin-top: spacing(1);
}

.photosDesc {
  max-width: 650px;
  align-self: flex-start;
  margin-bottom: spacing(4);
}
