@import 'src/styles/exports';
@import './variables.scss';
@import './animation.scss';
//@import './confirm.scss';

$text-color: $black-3;

body.toastr-confirm-active {
  overflow: hidden;
}

.redux-toastr {
  *, *:before, *:after {
    box-sizing: border-box;
  }

  .top-left, .top-right, .top-center, .bottom-left, .bottom-right, .bottom-center {
    position: fixed;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    //align-items: flex-start;
  }

  .top-left, .top-right, .top-center {
    top: 30px;
  }

  .top-right, .bottom-right {
    right: spacing(2);
  }

  .bottom-left, .bottom-right, .bottom-center {
    bottom: spacing(3);
  }

  .top-left, .bottom-left {
    left: spacing(3);
  }

  .top-center, .bottom-center {
    left: 0;
    right: 0;
    justify-content: center;
  }

  .toastr {
    background-color: $common-white;
    width: 100%;
    max-width: 310px;
    min-width: $toastr-width;
    //overflow: hidden;
    //margin: spacing(1) 0;
    margin: auto auto spacing(1) auto;
    border-radius: 8px;
    position: relative;
    z-index: 2;
    outline: none;
    box-shadow: 10px 20px 60px rgba(0, 0, 0, 0.25);

    &.rrt-success {
      background: $primary-gradient-color;

      & > div {
        padding: 10px;
        & > .rrt-middle-container {

          & > div:first-child {
            font-family: $font-family-main;
            color: $black-2;
            font-weight: 600;
            font-size: 14px;

            @include respond-to(sm) {
              font-size: 18px;
            }

          }

          & > div:last-child {
            font-family: $font-family-main;
            color: $black-2;
            font-weight: 500;

            font-size: 12px;

            @include respond-to(sm) {
              font-size: 14px;
            }
          }

        }
      }

    }

    &.rrt-info {
      //border-left: 3px solid $infoBgColor;
      background: linear-gradient(180deg, #7D50FF 0%, #D196FF 100%) !important;
    }

    &.rrt-warning {
      border-left: 3px solid $warningBgColor;
    }

    &.rrt-error {
      background: $error-color;
    }

    > div {
      min-height: 48px;
      padding: spacing(2);
      display: flex;
    }

    .toastr-icon {
      margin-right: spacing(1.5);
      color: $common-white;
    }

    .rrt-middle-container {
      flex: 1 1 auto;
      //padding: 0 spacing(2);

      display: flex;
      flex-direction: column;
      align-items: center;

      .rrt-title {
        @include font-title();
        margin-bottom: 10px;
        font-size: 18px;
      }

      .rrt-text{
        font-family: $font-family-secondary;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        text-align: center;

        color: $common-white;
      }
    }

    .rrt-right-container {
      position: absolute;
      top: -10px;
      right: -10px;


    }

    .rrt-left-container {
      display: none;
    }

    .close-toastr {
      @include circle(26);
      color: $text-color;
      cursor: pointer;
      background: $common-white;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);

      &:focus {
        outline: none;
      }
    }


    &.rrt-message {
      opacity: 1;

      .rrt-title {
        width: 90%;
        height: 50px;
        text-align: center;
        overflow: hidden;
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 50px;
        padding: 0 20px;
      }

      .rrt-text {
        width: 100%;
        max-height: 400px;
        overflow: hidden;
        overflow-y: auto;
        padding: 15px;
        font-size: 0.75rem;
        margin-bottom: 20px;

        img {
          display: block;
          margin: 10px auto;
          max-width: 100%;
        }
      }

      .close-toastr {
        height: 50px;
      }
    }

    .rrt-progress-container {
      height: 5px;
      margin: 0 -20px -20px -60px;
      position: absolute;
      bottom: 20px;
      width: 100%;

      .rrt-progressbar {
        -webkit-border-radius: 0 0 0 4px;
        -moz-border-radius: 0 0 0 4px;
        border-radius: 0 0 0 4px;
        height: 100%;
      }
    }
  }

  .toastr-attention {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
}
