.root{
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    font-size: 20px;

    @media screen and (max-width:1200px){
        font-size: 15px;
    }
}

.light{
    color: white;
}

.dart{
    color: black;
}