@import 'src/styles/exports';

@import 'src/libs/toastr/styles/variables';

.root {
  @include circle(40);
  color: $common-white;

  &.success {
    background-color: $successBgColor;
  }

  &.info {
    background-color: $infoBgColor;
  }

  &.warning {
    background-color: $warningBgColor;
  }

  &.error {
    background-color: $errorBgColor;
  }
}
