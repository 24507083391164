@import "src/styles/exports";

.dialog {
  min-height: 100px;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  max-height: 790px;
  height: 100%;
  //width: 100%;


  @include respond-to(lg) {
    height: 790px;
    min-width: 600px;
  }
}


.closeIcon {
  color: $gray-1;
  cursor: pointer;
}

.contentDialog {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.headerDialog {
  display: flex;

  @include respond-to(lg) {
    justify-content: flex-end;
  }
}

.titleDialog {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-family: $font-family-secondary;
  font-size: 30px;
  line-height: 35px;
}

.inputDialog {
  flex-grow: 2;
  display: flex;
  align-items: flex-start;
  @include my(2)
}

.buttons {
	display: flex;
	justify-content: space-evenly;
}

.btn {
  max-width: 100px;
  height: 50px !important;
  align-self: flex-end;


  @include respond-to(lg) {
    max-width: 180px;
    height: 70px !important;
    align-self: center;

    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
  }
}

.cancelBtn {
		color: $gray-2 !important;
		background: $common-white !important;
		border: 1px solid $gray-2 !important;
}


.input {
  resize: none;
}
