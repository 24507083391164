@import "src/styles/exports";

.root {
  width: 100%;
}

.input {
  border-color: $primary-color;
  width: 100%;
  border-radius: $border-radius-1;
  padding: spacing(2);

  font-size: 16px;
  line-height: 18px;
  font-family: $font-family-main;

  color: $black-3;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.maxLength {
	color: $primary-color;
	font-family: $font-family-main;
	font-size: 13px;
	position: absolute;
	right: 15px;
	bottom: 15px;
}
