@import "src/styles/exports";

.root {
  display: flex;
  flex-wrap: wrap;
  margin: -7px;

  @include respond-to(lg) {
    margin: -10px -10px
  }
}

.card {
  flex: 1 1 300px;
  margin: 7px;

  @include respond-to(lg) {
    margin: 10px 10px
  }
}

