@import "src/styles/exports";

.root {
  width: inherit;
}
.radioButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  justify-content: space-between;
  //

  @include respond-to(lg) {
    padding-right: spacing(1);
  }
}

.margin {
  margin-top: 10px !important;
}

.displayedValueContainer {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  padding-top: spacing(1);
  @include respond-to(lg) {
    padding-right: spacing(1.5);
    margin-bottom: unset;
  }
}

.chip {
  flex: 1 1 auto;
  margin: 4px;
  height: 50px !important;
  padding: 0 10px;

  @include respond-to(lg) {
    flex: 1 1 260px;
    height: $input-md-height !important;
    font-weight: 600;
    font-size: 20px !important;
    line-height: 27px !important;
  }
}

.letter {
  width: 100%;
  text-align: center;
  padding: 20px 0 10px 0;
  font-family: $font-family-main;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 25px;
  position: sticky;
  top: -20px;
  background-color: $gray-5;
}