@import "~normalize.css";
@import "bootstrap";
@import "exports";
@import 'globals';
@import 'utils';
@import 'typography';
@import 'spacing';
@import 'fonts';
@import 'src/libs/toastr/styles/index';

body {
  background-color: $common-white;
  margin: 0;
  font-family: -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  Montserrat,
  Caladea,
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

#root {
  height: inherit;
  width: 100vw;
  min-height: unset;
}

hr {
  width: 100%;
  background-color: $gray-5;
  height: 1px;
  border: none;
}
