@import "src/styles/exports";

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 100%;
  border: 1px $primary-color solid;
  border-radius: $border-radius-1;
  padding: spacing(1);
  caret-color: $black-1;

  font-weight: 500;
  font-family: $font-family-main;
  color: $black-3;
  position: relative;

	height: 50px;
  font-size: 20px;

	@include respond-to(sm) {
		height: 60px;
	}

  @include respond-to(sm) {
    font-size: 30px;
  }

  &::-webkit-input-placeholder {
    color: $gray-1;
    font-size: 20px;
    font-weight: 400;
    line-height: 17px;

    @include respond-to(sm) {
      font-size: 30px;
      line-height: 35px;
    }
  }

  &.disabled {
    background: $gray-5;
    cursor: auto;
  }

  &:focus {
    background: #F7EDFF;
  }
}

.root {
  width: 100%;
  //width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
}

.tooltipIcon {
  position: absolute;
  right: 0;
  transform: translateX(-20px);
  cursor: pointer;

  &:hover {
    color: $purple-1;
  }
}

.error {
  border: 2px solid $error-color !important;
}

.verified {
  border: 2px solid $success-color !important;
}

.dialogRoot {
  border-radius: 8px !important;
  background-color: $beige-1 !important;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltipContent {
  font-size: 20px;
  padding: spacing(3);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.tooltipTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #C494EB;
}

.tooltipText {
  font-size: 14px;
  line-height: 19px;
}

.errorIcon {
  color: $error-color;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
}

.successIcon {
  color: $success-color;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
}