$font-size-main: 14px;
$font-size-xs: 10px;
$font-size-small: 12px;
$font-size-big: 24px;
$button-size: 40px;

$border-radius: 15px;
$border-radius-1: 8px;


$h1-font-size: 32px;
$h2-font-size: 26px;
$h3-font-size: 20px;
$h4-font-size: 16px;
$h5-font-size: 14px;

$input-sm-height: 50px;
$input-md-height: 60px;
$input-lg-height: 70px;
