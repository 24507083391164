@import "src/styles/exports";

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-bottom: spacing(2);
}

.subTitle {
  font-weight: 400;
  text-align: center;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 20px;
}

.actions {
  display: flex;
  margin-top: spacing(2);

  & > button {
    flex-grow: 1;
    max-width: 190px;

    &:first-child {
      color: $common-white !important;
      background: $purple-gradient !important;
      box-shadow: $header-text-shadow;
      height: 40px !important;
      padding: 0 spacing(3) !important;
      //margin-right: spacing(2);
    }
  }
}
