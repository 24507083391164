@import "src/styles/exports";

.arrow {
  color: $purple-2 !important;
}

.tooltip {
  background-color: $purple-2 !important;
  border-radius: 8px !important;
  max-width: 377px !important;
}


.tooltipIcon {
  position: absolute;
  right: 0;
  transform: translateX(-20px);
  cursor: pointer;

  &:hover {
    color: $purple-1;
  }
}

.error {
  border: 2px solid $error-color !important;
}

.dialogRoot {
  border-radius: 8px !important;
  background-color: $beige-1 !important;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltipContent {
  font-size: 20px;
  padding: spacing(3);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.tooltipTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #C494EB;
}

.tooltipText {
  font-size: 14px;
  line-height: 19px;
}
