.slick-track {
	height: inherit;
}

.slick-slider {
	width: 100%;
	height: inherit;
}

.slick-list {
	height: inherit;
}

.slick-initialized .slick-slide {
	display: flex;
	align-items: center;
}
