@import "src/styles/exports";

.root {
  display: flex;
  justify-content: center;
}

.inputsContainer {
  display: grid !important;
  grid-auto-flow: column;
  max-width: 310px;
  grid-column-gap: 10px;
  grid-auto-columns: minmax(30px, 50px);

  @include respond-to(lg) {
    grid-auto-columns: minmax(50px, 70px)
  }

  & input:focus {
  background-color: #F7EDFF !important;
  }
}

.input {
  text-align: center;
	@include respond-to(sm) {
		font-size: 2vw !important;
	}
	@include respond-to(lg) {
		font-size: 25px !important;
	}
}

.timer {
  width: fit-content !important;
  margin-left: 10px;
  max-width: 85px;
  min-width: 85px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  font-family: $font-family-main;
  line-height: 21px;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  @include respond-to(lg) {
    max-width: 120px;
    min-width: 90px;
    font-size: 30px;
    line-height: 40px;
  }
}
