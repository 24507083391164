@import "src/styles/exports";

html {
  font-size: $font-size-main;
  font-family: $font-family-main;
  letter-spacing: 0.03em;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
}

.heading-1 {
  font-family: $font-family-main;
  font-weight: 500;
  font-style: normal;
  color: $black-2;
}

.heading-2 {
  font-family: $font-family-main;
  font-weight: 600;
  font-style: normal;
  color: $black-2;
}

.heading-3 {
  font-family: $font-family-secondary;
  font-weight: 700;
  font-style: normal;
  color: $common-white;
}

.heading-4 {
  font-family: $font-family-secondary;
  font-weight: 700;
  font-style: normal;
  color: $primary-color;
}

.heading-5 {
  font-family: $font-family-secondary;
  font-weight: 700;
  font-style: normal;
  color: $gray-1;
}

.heading-6 {
  font-family: $font-family-main;
  font-weight: 600;
  font-style: normal;
  color: $common-white;
}

.heading-7 {
  font-family: $font-family-main;
  font-weight: 600;
  font-style: normal;
  color: $black-3;
}

.body-1 {
  font-family: $font-family-main;
  font-weight: 500;
  font-style: normal;
  color: $black-3;
}

.body-2 {
  font-family: $font-family-secondary;
  font-weight: 700;
  font-style: normal;
  color: $black-2;
}

.body-3 {
  font-family: $font-family-main;
  font-weight: 500;
  font-style: normal;
  color: $gray-2;
}

.body-4 {
  font-family: $font-family-main;
  font-weight: 400;
  font-style: normal;
  color: $common-white;
}

.body-5 {
  font-family: $font-family-main;
  font-weight: 400;
  font-style: normal;
  color: $black-2;
}

.body-6 {
  font-family: $font-family-main;
  font-weight: 600;
  font-style: normal;
  color: $black-1;
}

.font-semi-bold {
  font-weight: 600;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-nowrap {
  white-space: nowrap;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-italic {
  font-style: italic;
}

.text-xs {
  font-size: $font-size-xs;
}

.text-sm {
  font-size: $font-size-small;
}

.text-lg {
  font-size: $font-size-big;
}

.text-gray {
  color: $gray-1;
}

.text-black {
  color: $black-2 !important;
}

.font-main {
  font-family: $font-family-main;
}

.color-primary {
  color: $primary-color;
}

