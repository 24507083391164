@import "src/styles/exports";

.root {
  display: grid;
  grid-template-columns: minmax(15px, 30px);
  grid-row-gap: spacing(1);
  z-index: 999;
  grid-auto-rows: max-content;

  @include respond-to(sm) {
    grid-template-columns: minmax(30px, 60px);
    grid-row-gap: spacing(2) ;
  }

  @include respond-to(xxl) {
    grid-row-gap: spacing(3);
  }
}

.step {
  cursor: pointer;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $gray-4;
  justify-self: center;
  box-shadow: $btn-box-shadow;
  transition: background-color 1s;

  @media (min-width: 1366px) {
    width: 12px;
    height: 12px;
  }

  &.active {
    width: 50px;
    height: 50px;
    background: $primary-gradient-color;
    color: $common-white;
    font-family: $font-family-secondary;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1367px) {
      width: 60px;
      height: 60px;
      font-size: 50px;
      line-height: 58px;
    }
  }
}
