@import "src/styles/exports";

.photoCard {
  position: relative;
  border-radius: $border-radius-1;

  flex-direction: column;
  @include flex-center();
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;

  &.primary {
    background-color: $photo-card-color;
  }

  &.secondary {
    background-color: $gray-7;
  }
}

.iconBackground {
  @include circle(26);
  background-color: $common-white;
}

.placeholder {
  text-align: center;
  color: $primary-color;
  font-size: 12px;
  margin-top: spacing(2);
}

.input {
  opacity: 0;
  top: 0;
  width: 100%;
  bottom: 0;
  position: absolute;
  cursor: pointer;
}

.image {
  width: inherit;
  height: inherit;
}

.icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
