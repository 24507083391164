@import "src/styles/exports";

.root {
  position: relative;
  height: 35px;
  //max-width: 100px;
  background: $purple-gradient;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: $border-radius-1;
  @include flex-center();
  color: $common-white;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  font-family: $font-family-main;
  text-align: center;
}

.iconContainer {
  @include circle(18);
  background: white;
  position: absolute;
  right: -7px;
  top: -7px;
  cursor: pointer;
}

.icon {
  width: 8px;
  height: 8px;
  color: $gray-2;
}
