@import "src/styles/exports";

.root {
  grid-area: privacy;
  display: flex;
  flex-direction: column;
  text-align: center;
  //padding: 25px;

  @include respond-to(sm) {
    text-align: start;
    //padding: 25px 45px
  }
}

.infoText {
  font-size: 10px;
  line-height: 11px;

  @include respond-to(sm) {
    font-size: 10px;
    line-height: 13px ;

    text-align: start;
  }

}

.infoTextLink{
  @extend .infoText;
  color: $common-white;
  font-weight: bold;
	cursor: pointer;
}
.privacy {
  white-space: pre;
}
