@import "src/styles/exports";

$stepper-width: 60px;

.root {
  height: inherit;
  display: flex;
  flex-direction: column;
}

.stepper {
  //margin-left: 120px;
  margin-left: 5vw;
  justify-content: right;
  margin-top: 68px;
  align-self: flex-start;

  //@include respond-to(lg) {
  //  width: calc(32px + 60px);
  //}
  //
  //@media (min-width: 1500px) {
  //  width: calc(120px + 60px);
  //}
}

.backIcon {
	padding-left: 5vw;
	cursor: pointer;
}

.content {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  overflow: auto;

}

.infoDialogBody {
  font-weight: bold !important;
  font-size: 18px !important;
}