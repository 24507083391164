@import "src/styles/exports";

.root{
  z-index: 100;
  @include flex-center;
  padding: 25px 30px;
  min-height: 300px;
  flex-direction: column;

  @include respond-to(sm) {
    padding: 0 65px;
  }
}

.modal {
  background: $common-white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
}

.title {
  font-family: $font-family-main;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  letter-spacing: -0.005em;
  color: $primary-color;
  margin-bottom: 20px;
}

.body {
  font-family: $font-family-main;
  font-style: normal;
  text-align: center;
  letter-spacing: -0.005em;
  color: $black-2;
  font-weight: 500;
  font-size: 16px;
}

.btn {
  padding: 0 spacing(5) !important;
  height: 45px !important;
  margin-top: 25px;
  width: 140px;

  @include respond-to(sm) {
    height: 50px !important;
    margin-top: 30px;
    width: 200px;
  }
}

.icon {
  width: 40px;
  margin-bottom: 10px;

  @include respond-to(sm) {
    width: 50px;
    margin-bottom: 20px;
  }
}