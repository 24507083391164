@import "src/styles/exports";

.root {
  background: $purple-gradient;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  max-height: 220px;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  align-items: center;
  //margin: 20px 0;
  padding: 20px 0;
  //margin: 20px;
}

.carouselRoot {
  width: 100%;
  position: relative;
}

.title {
  font-style: normal;
  font-weight: bold;
  color: $common-white;
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  font-family: $font-family-secondary;

  @include respond-to(sm) {
    //font-size: 42px;
    //line-height: 48px;
    font-size: 3vw;
    line-height: 3vw;
    text-align: start;
    margin-bottom: 25px;
  }
}

.subTitle {
  font-family: $font-family-secondary;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: $common-white;
  text-align: start;

  @include respond-to(sm) {
    font-size: 18px;
    line-height: 22px;
  }

  @include respond-to("4k"){
    font-size: 30px;
    line-height: 35px;
  }
}

.dot{
  background-color: $blue !important;
  border: none !important;
}

.activeDot {
  background-color: $common-white !important;
}

.dotsContainer {
  position: relative;
  //bottom: -80px;
}

.slide {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  padding: 0 30px;
}

.btn {
  margin-top: spacing(2);
  padding: 0 40px !important;
  max-width: 250px;
  min-height: 40px;

  & > div {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #3E3E3E;
  }
}

.wrapper {
  padding: 10px;
  display: flex;
  justify-content: center;
  width: 100%;

  @include respond-to(lg) {
    padding: 20px;
  }
}

.slideDescription {
  position: absolute;
  bottom: 25px;
  z-index: 10;
  width: 100%;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include respond-to(sm) {
    height: 40%;
    padding: 0 45px;
    bottom: 60px;
  }
}

.img {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
}

.gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background:linear-gradient(180deg, rgba(212, 157, 255, 0.04) 0%, #7D50FF 72.89%)
}

.list {
  padding-inline-start: 20px;

  & li {
    margin-bottom: 10px;

    @include respond-to(sm) {
      margin-bottom: 30px;
    }
  }

  @include respond-to(sm) {
    padding-inline-start: 25px;
    margin-bottom: 60px;
  }
}

.slideTitle {
  font-size: 24px;
  line-height: 28px;
  font-family: $font-family-secondary;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: $common-white;
  margin-bottom: 10px;
  margin-top: 20px;
}

.slideSubtitle {
  font-family: $font-family-main;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: $common-white;
  text-align: center;
}