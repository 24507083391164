@import "src/styles/exports";

.root {
  display: flex;
  align-items: center;
  background: $primary-gradient-color;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
}

.icon {
  width: 150px;
  height: 130px;
  color: $common-white;

  @include respond-to(sm) {
    width: 200px;
    height: 180px;
  }
}

.text {
  font-family: $font-family-main;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: $common-white;
  text-align: center;
  margin-top: 30px;
  max-width: 500px;

  @include respond-to(sm) {
    font-size: 22px;
    line-height: 30px;
  }
}

.btn {
  background: none !important;
  border: 1px solid $common-white;
  width: 130px !important;
  height: 40px !important;
  padding: unset !important;
  margin-bottom: 50px;

  @include decrease-to(se) {
    width: 130px !important;
  }

  @include respond-to(sm) {
    width: 200px !important;
    height: 50px !important;
    margin-bottom: 150px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 35px;
}

.logo {
  margin-top: 30px;
}

.btnsContainer {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}