@import 'src/styles/exports';

$toastr-bg: $error-color;

$toastr-width: 200px !default;
$successBgColor: #9CE7CC !default;
$infoBgColor: $purple-1 !default;
$warningBgColor: $secondary-color !default;
$errorBgColor: $error-color !default;
$defaultSpace: 10px;
