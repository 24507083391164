@import "src/styles/exports";

.root {
  border: 1px solid $primary-color;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 20px;
  font-family: $font-family-main;
  font-weight: 500;
  -webkit-appearance: none;
  height: $input-sm-height;
  width: 100%;

  &:focus {
    background-color: #F7EDFF !important;
  }

  @include respond-to(sm) {
    font-size: 26px;
    height: $input-md-height;
  }
}

.error {
  border: 2px solid $error-color !important;
}

.arrow {
  color: $primary-color;
  position: absolute;
  top: 35px;
  right: 10px;
}

.option {
  padding: 10px;
  background-color: $gray-12;


  &:hover {
    background: #FFEAE3;
  }
}