@import "src/styles/exports";

.root {
  display: flex;
  min-height: 57px;
  padding: 0 spacing(4);
  justify-content: space-between;
  align-items: flex-end;
  z-index: 1;

  @include respond-to(sm){
    height: 90px;
    min-height: 90px;
    align-items: center;
		padding: 0 5vw;
  }
}

.closeIcon {
  color: $gray-1;
  cursor: pointer;
}

.successStoriesBtn {
		background-color: $purple-1 !important;
		color: white !important;
}
