@import "src/styles/exports";

.root {
  background: $primary-gradient-color-2;
  height: inherit;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  padding: 0 spacing(4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.title {
  font-size: 40px;
  line-height: 39px;

  @include respond-to(lg) {
    text-align: center;
    font-size: 96px;
    line-height: 92px;
  }
}

.description {
  font-size: 14px;
  line-height: 17px;
  margin-top: spacing(2);

  @include respond-to(lg) {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textWrapper {
  display: flex;
  flex-direction: column;
}
