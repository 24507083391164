@import "src/styles/exports";

$icon-wrapper-width: 50px;

.root {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  position: absolute;
  right: 0;
  transform: translateX(-15px);
  cursor: pointer;
  z-index: 999;
  width: 22px;
  height: 20px;

  @include respond-to(lg) {
    width: 18px;
    height: 29px;
  }
}

.input {
  border-right: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-right: 0;
  color: $black-3;
  font-weight: 600;
  font-family: $font-family-main;
  font-size: 16px;
  line-height: 19px;

  @include respond-to(lg) {
    font-size: 26px;
    line-height: 31px;
  }
}

.rootInput {
  width: calc(100% - 50px);
}

.iconWrapper {
  height: $input-sm-height;
  width: $icon-wrapper-width;
  display: flex;
  align-items: center;
  background-color: $common-white;

  border: 1px $primary-color solid;
  border-left: 0;
  border-radius: 0 $border-radius-1 $border-radius-1 0 ;

  @include respond-to(sm) {
    height: 60px;
  }
}
